import React from "react";

export default function FourOhFour() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "coral",
      }}
    >
      404 Not Found!
    </div>
  );
}
